import BaselineView from "../components/baselines/BaselineView"
import HealthCheckView from "../components/healthchecks/HealthCheckView"
import PageNotAccessable from "./PageNotAccessable"
import MenuBar from "../components/MenuBar"
import TopNavBar from "../components/TopNavBar"
import Theme from "../Theme"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { Route, Routes, Link, useNavigate, useLocation, useSearchParams } from "react-router-dom"
import Breadcrumb from "../components/utility/Breadcrumb";
import { ReportPageStyle } from "../models/StyleModel"

import { useEffect, useState } from "react"
import { approveIgnoredResource, getBauBaselines, getCustomers, getCustomer, getHealthCheckData, getServices, removeResourceFromIgnoreList, toggleCustomerEnabledInDb } from "../service/apiAccessLogic"
import { updateBaselineInfo, updateCustomerInfo, updateReportInfo, updateHealthCheckInfo, setIgnoredResourceApproved, removeIgnoredResource, addIgnoredResource, updateCustomerEnabledStatus } from "../redux/customerSlice"
import { msalInstance } from ".."
import { setAllCustomersID, setBaselines, setCurrentAcceptedBaseline, setCurrentCustomerId, setCurrentHistoricalBaseline, setDisplayBaselineDifferences, setLastSyncTime } from "../redux/dataSlice"
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice"
import { getSyncTimeString } from "../service/menuLogic"
import { MdOutlineErrorOutline, MdOutlineCheckCircleOutline, MdOutlineHighlightOff } from "react-icons/md"
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CustomerBackground from "../resource/DEFEND-Main-About.jpg"
import { useParams } from "react-router-dom";
import { HealthCheck } from "../models/HealthCheckModel"
import { IgnoredResource, ToggleCustomerEnabledInDbRequest } from "../models/CustomerModel"
import { REPORT_ADMIN_ROLE } from "../models/EditorModel"
import ConfirmActionModal from "../components/forms/ignoreList/ConfirmActionModal"
import PendingIgnoreComponent from "../components/healthchecks/PendingIgnoreComponent"
import PendingIgnoreModal from "../components/healthchecks/PendingIgnoreModal"
import RemoveIgnoredResourceForm from "../components/forms/ignoreList/RemoveIgnoredResourceForm"
import RoundButton from "../components/utility/RoundButton";

const CustomerPage = () => {
  const userMode = useAppSelector((state) => state.user.userMode);
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const allCustomersID = useAppSelector((state) => state.data.allCustomersID)
  
  const currentCustomerIdFromState = useAppSelector((state) => state.data.currentCustomerId)
  const currentCustomerIdFromUrl = useParams().customerId;
  const currentCustomerId = currentCustomerIdFromState ? currentCustomerIdFromState : currentCustomerIdFromUrl;
  const isValidCustomer = allCustomersID.includes(currentCustomerId as string);
  const userRoles = useAppSelector(state => state.user.userRoles);
  
  const currentAcceptedBaseline = useAppSelector((state) => state.data.currentAcceptedBaseline);
  const currentHistoricalBaseline = useAppSelector((state) => state.data.currentHistoricalBaseline);
  const currentBaselineIdFromState = currentAcceptedBaseline?.id;
  const currentBaselineIdFromUrl = useParams().baselineId;
  const currentBaselineId = currentBaselineIdFromState ? currentBaselineIdFromState : currentBaselineIdFromUrl;
  
  const currentSentinelWorkspace = useAppSelector((state) => state.data.currentSentinelWorkspace);
  const currentCustomerData = useAppSelector((state) => state.customer.customer_info);
  const reportInfo = useAppSelector((state) => state.customer.report_info);
  const baselineInfo = useAppSelector((state) => state.customer.baseline_info);
  const baselines = useAppSelector((state) => state.data.baselines);
  const healthchecks = useAppSelector((state) => state.customer.healthcheck_info);
  const services = currentCustomerData.products.map((service) => service.name);
  const jira_configs = currentCustomerData.products.filter((product) => product.jira_record);

  const allIgnoredResources = useAppSelector((state) => state.customer.healthcheck_info.ignoredResources);
  const ignoredResources = allIgnoredResources.filter(r => r.approved); 
  const [confirmingIgnore, setConfirmingIgnore] = useState(undefined as (IgnoredResource | undefined));
  const [rejectingIgnore, setRejectingIgnore] = useState(undefined as (IgnoredResource | undefined));
  const [openRemoveIgnoredResourceForm, setOpenRemoveIgnoredResourceForm] = useState(undefined as (IgnoredResource | undefined));
  const [approvingIgnoreModal, setApprovingIgnoreModal] = useState(undefined as (IgnoredResource | undefined));
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams()
  const approveIgnoredResourceName = searchParams.get("approveIgnoredResourceName")
  
  if (currentCustomerIdFromState && currentCustomerIdFromState !== currentCustomerIdFromUrl) {
    // Update path to match state
    const currentPath = window.location.pathname;
    if (currentPath === "/customers") {
      window.history.pushState({}, '', `/customers/${currentCustomerIdFromState}`);
    } else if (currentCustomerIdFromUrl) {
      window.history.pushState({}, '', currentPath.replace(currentCustomerIdFromUrl, currentCustomerIdFromState));
    }
  }

  useEffect(() => {
    // navigate to the current customer if the current customer id in the state is different from the current customer id in the url
    if (currentCustomerIdFromState && currentCustomerIdFromState !== currentCustomerIdFromUrl) {
      const newPath = location.pathname.replace(
        new RegExp(`customers/${currentCustomerIdFromUrl}`), 
        `customers/${currentCustomerIdFromState}`
      );
      navigate(newPath, { replace: true });
    }
  }, [currentCustomerIdFromState, currentCustomerIdFromUrl, navigate, location.pathname]);

  useEffect(() => {
    // set current baseline to the current accepted baseline by default if it exists and navigate to the baseline view
    if (currentAcceptedBaseline && Object.keys(currentAcceptedBaseline).length > 0 && currentBaselineId) {
      if (pathSegments.includes('baselines')) navigate(`/customers/${currentCustomerId}/baselines/${currentAcceptedBaseline?.id}`)
    }
  }, [currentAcceptedBaseline, currentBaselineId, currentCustomerId, navigate]);

  useEffect(() => {
    if (!currentCustomerId || Object.keys(baselines).length === 0) return;
    // if the current customer id has any current accepted baselines, set it as the current baseline, else set the current baseline as null
    if (allCustomersID.length > 0 && isValidCustomer) {
      const hasBaselines = Object.keys(baselines[currentCustomerId]).length > 0;
      if (hasBaselines) {
        // grab the workspace with the latest baseline update
        const workspace = Object.keys(baselines[currentCustomerId]).reduce((a, b) => baselines[currentCustomerId][a].date > baselines[currentCustomerId][b].date ? a : b);
        const customerBaseline = baselines[currentCustomerId][workspace];
        dispatch(setCurrentAcceptedBaseline(customerBaseline));
      } else {
        dispatch(setCurrentAcceptedBaseline(null));
      }
    }
  }, [allCustomersID, isValidCustomer, currentCustomerId, baselines, dispatch]);
  
  
  useEffect(() => {
    if (approveIgnoredResourceName){
      if (allIgnoredResources.length > 0) {
        const resource = allIgnoredResources.find(r => r.resource_name === approveIgnoredResourceName);
        if (resource) {
          setApprovingIgnoreModal(resource);
        }
      }
    }
  }, [allIgnoredResources])

  const getComponentHealthStatus = (
    components: HealthCheck[],
    defaultStatus: string,
    ignoredResources: IgnoredResource[] = []
  ) => {
    if (!components || components.length === 0) {
      return defaultStatus;
    }

    const ignored = components.filter((s: HealthCheck) => 
      ignoredResources.find((r: IgnoredResource) => r.resource_name === s.ResourceName && r.resource_type === s.ResourceType)
    );
  
    if (components.findIndex((s: HealthCheck) => {
      return s.HealthStatus === "Unhealthy" && !ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
    }) !== -1) return "Unhealthy";
  
    if (components.findIndex((s: HealthCheck) => {
      return s.HealthStatus === "AtRisk" && !ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
    }) !== -1) return "AtRisk";
  
    if (components.every((s: HealthCheck) => {
      return s.HealthStatus === "Healthy" || ignored.find((r: HealthCheck) => r.ResourceName === s.ResourceName && r.ResourceType === s.ResourceType)
    })) return "Healthy";

    return "Unknown";
  };

  const healthSummary = {
    hourly: {
      id: healthchecks.hourly[0]?.id,
      time: healthchecks.hourly[0]?.time,
      automation_rules: getComponentHealthStatus(healthchecks.hourly[0]?.health_check.automation_rules, "Unknown", ignoredResources),
      data_connectors: getComponentHealthStatus(healthchecks.hourly[0]?.health_check.data_connector_zero_logs, "Unknown", ignoredResources),
      data_connector_failures: getComponentHealthStatus(healthchecks.hourly[0]?.health_check.data_connector_failures, "Unknown", ignoredResources),
      logic_apps: getComponentHealthStatus(healthchecks.hourly[0]?.health_check.logic_apps, "Unknown", ignoredResources),
    },
    daily: {
      id: healthchecks.daily[0]?.id,
      time: healthchecks.daily[0]?.time,
      data_connector_zero_logs: getComponentHealthStatus(healthchecks.daily[0]?.health_check.data_connector_zero_logs, "Unknown", ignoredResources),
    },
    weekly: {
      id: healthchecks.weekly[0]?.id,
      time: healthchecks.weekly[0]?.time
    }
  };

  const loadCustomerDetails = async () => {
    dispatch(activateFullScreenPrompt("loading"))

    await getCustomer(msalInstance, currentCustomerId as string)
      .then((response) => {
        dispatch(updateCustomerInfo(response.data[0]["customer_info"]))
        dispatch(updateReportInfo(response.data[0]["report_info"]))
        dispatch(updateBaselineInfo(response.data[0]["baseline_info"]))
      })
      .catch((e) => {
        console.log("Error getting customer info: " + e)
      });

    await getHealthCheckData(msalInstance, currentCustomerId as string)
      .then((response) => {
        dispatch(updateHealthCheckInfo(response.data))
      })
      .catch((e) => {
        console.log("Error loading healthcheck information: " + e)
      })

      dispatch(activateFullScreenPrompt("false"));
  }

  useEffect(() => {
    if (allCustomersID.length > 0 && baselines && Object.keys(baselines).length > 0 && currentCustomerId) {
      if (!isValidCustomer) {
        dispatch(setDisplayBaselineDifferences(false));
        return
      }
      dispatch(setCurrentCustomerId(currentCustomerId));
    }
  }, [allCustomersID, currentCustomerId, baselines, dispatch]);

  useEffect(() => {
    if (approveIgnoredResourceName) {
      dispatch(activateFullScreenPrompt("loading"));
    }

    if (allCustomersID.length > 0) return;
    getCustomers(msalInstance)
      .then((response) => {
        dispatch(setAllCustomersID(response.data));
      })
      .catch(() => {
        console.log("Error loading all customers ID");
      });
  }, []);

  useEffect(() => {
    // setting the current accepted baselines for all customers
    if (Object.keys(baselines).length !== 0) return
    getBauBaselines(msalInstance, "accepted_baselines")
      .then((response) => {
        if (response.status === 200) { dispatch(setBaselines(response.data)) }
        dispatch(setLastSyncTime({ time: getSyncTimeString(), type: "baseline" }));
      })
      .catch(() => {
        console.log("Error loading baselines")
      })
  }, [baselines, dispatch]);

  useEffect(() => {
    // setting the latest historical baseline for selected customer
    if (!currentCustomerId || !currentAcceptedBaseline) return;
    if (currentHistoricalBaseline && Object.keys(currentHistoricalBaseline).length > 0) return;
    const worksapce = currentSentinelWorkspace ? currentSentinelWorkspace : currentAcceptedBaseline.workspace
    getBauBaselines(msalInstance, "latest_hist_baseline", "", currentCustomerId, worksapce)
      .then((response) => {
        if (response.status === 200) { dispatch(setCurrentHistoricalBaseline(response.data)) }
      })
      .catch(() => {
        console.log("Error loading latest historical baseline record for customer: " + currentCustomerId);
      });
  }, [currentCustomerId, currentSentinelWorkspace, currentAcceptedBaseline, dispatch]);

  useEffect(() => {
    if (!currentCustomerId || currentCustomerData.customer_id === currentCustomerId) return
    loadCustomerDetails()
  }, [currentCustomerId, currentCustomerData]);

  let validateSentinelRecord = true
  if (currentCustomerData.sentinel_configs === undefined || currentCustomerData.sentinel_configs.length === 0) {
    validateSentinelRecord = false
  };

  let validateJiraRecord = true
  if (jira_configs === undefined || jira_configs.length === 0) {
    validateJiraRecord = false
  };

  let validateServices = true
  if (services === undefined || services.length === 0) {
    validateServices = false
  };

  const toggleCustomerEnabled = async (
    reqObj: ToggleCustomerEnabledInDbRequest,
  ) => {
    await toggleCustomerEnabledInDb(msalInstance, JSON.stringify(reqObj))
    .then((response) => {
      dispatch(updateCustomerEnabledStatus(response.data))
      dispatch(activateFullScreenPrompt("false"));
      return true;
    })
    .catch((e) => {
      console.log("Error in createCustomerInDb: " + e);
      dispatch(activateFullScreenPrompt("error"));
      return false;
    })
  };

  const renderCustomerInfo = () => {
    return (
      <div className="flex flex-col p-3 rounded-md space-y-2">
        <span className="text-sm font-semibold text-gray-600 self-center">{currentCustomerData["enabled"]? "Customer Info": "Customer Is Disabled"}</span>
        {isValidCustomer ? (
          <div>
            <div className="flex flex-row">
              <div className="flex-grow">
                <span className="text-sm font-semibold text-gray-600">Customer:{" "}</span>
                <span className="text-sm text-gray-500">{currentCustomerData?.customer_name}</span>
              </div>
              <div className="flex-grow">
                <span className="text-sm font-semibold text-gray-600">Tenant ID:{" "}</span>
                <span className="text-sm text-gray-500">{currentCustomerData?.tenant_id}</span>
              </div>
            </div>
            <div className="border-t border-gray-200 pt-1">
              <span className="text-sm font-semibold text-gray-600">Services:</span>
              {validateServices ? (
                <ul className="list-disc pl-5 space-y-1">
                  {services.map((service, index) => (
                    <li key={index} className="text-sm text-gray-500">{service}</li>
                  ))}
                </ul>
              ) : currentCustomerId ? (
                <span className="text-sm text-gray-500">
                  <li className="text-sm text-gray-500">No services configured</li>
                </span>
              ) : null}
            </div>
            <div className="border-t border-gray-200 pt-1">
              <span className="text-sm font-semibold text-gray-600">Logged In Users:</span>
              <ul className="list-disc pl-5 space-y-1">
                {currentCustomerData.logged_in_users.map((user, index) => (
                  <li key={index} className="text-sm text-gray-500">{user}</li>
                ))}
              </ul>
            </div>
            <div className="border-t border-gray-200 pt-1">
              <span className="text-sm font-semibold text-gray-600">Jira:</span>
              {validateJiraRecord ? (
                <div>
                  {jira_configs.map((jira_config, index) => (
                    <div key={index} className={`pt-1`}>
                      <div className={`ml-3 ${index > 0 ? "border-t border-gray-300 pt-2" : ""}`}>
                        <div className="text-sm text-gray-500">Service: {jira_config.name}</div>
                        <div className="text-sm text-gray-500">Organisation: {jira_config.jira_record.organisation}</div>
                        <div className="text-sm text-gray-500">Customer: {jira_config.jira_record.customer}</div>
                        <div className="text-sm text-gray-500">Project: {jira_config.jira_record.project}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : currentCustomerId ? (
                <span className="text-sm text-gray-500">
                  <li className="text-sm text-gray-500">No Jira project configured</li>
                </span>
              ) : null}
            </div>
            <div className="border-t border-gray-200 pt-1">
              <span className="text-sm font-semibold text-gray-600">Sentinel workspaces:</span>
              {validateSentinelRecord ? (
                <div>
                  {currentCustomerData.sentinel_configs.map((sentinel, index) => (
                    <div key={index} className={`pt-1`}>
                      <div className={`ml-3 ${index > 0 ? "border-t border-gray-300 pt-2" : ""}`}>
                        <div className="text-sm text-gray-500">Subscription ID: {sentinel.subscription_id}</div>
                        <div className="text-sm text-gray-500">Resource group: {sentinel.resource_group}</div>
                        <div className="text-sm text-gray-500">Workspace: {sentinel.workspace}</div>
                        <div className="text-sm text-gray-500 pb-2">Workspace ID: {sentinel.workspace_id}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : currentCustomerId ? (
                <span className="text-sm text-gray-500">
                  <li className="text-sm text-gray-500">No Sentinel workspaces configured</li>
                </span>
              ) : null}
            </div>
            <div hidden={!userRoles.includes(REPORT_ADMIN_ROLE)}>
              <RoundButton
                text={currentCustomerData["enabled"] ? "Disable Customer" : "Enable Customer"}
                colour={currentCustomerData["enabled"] ? Theme.Colors.Error : Theme.Colors.Primary}
                extraStyle={"w-40 mt-3"}
                disabled={!userRoles.includes(REPORT_ADMIN_ROLE)}
                onClickAction={() => {
                  dispatch(activateFullScreenPrompt("loading"))
                  let toggleEnabledReqObj: ToggleCustomerEnabledInDbRequest = {
                      id: currentCustomerId as string,
                      toggle_reporting: false              
                    }
                    toggleCustomerEnabled(toggleEnabledReqObj)
                  }} 
              />
            </div>            
          </div> 
        ) : (
          <span className="text-sm">No customer selected</span>
        )}
      </div>
    );
  }

  const renderHealthchecks = () => {
    const statusIcon = (status: string) => {
      switch (status) {
        case "Healthy":
          return <MdOutlineCheckCircleOutline className="text-green-500" size={24} />;
        case "Unknown":
          return <MdOutlineErrorOutline className="text-yellow-500" size={24} />;
        default:
          return <MdOutlineHighlightOff className="text-red-500" size={24} />;
      }
    };

    const statusIconHealthUnknown = (status: string) => {
      switch (status) {
        case "Healthy":
          return <MdOutlineCheckCircleOutline className="text-green-500" size={24} />;
        case "Unknown":
          return <MdOutlineCheckCircleOutline className="text-green-500" size={24} />;
        default:
          return <MdOutlineHighlightOff className="text-red-500" size={24} />;
      }
    };

    const hourly_time_formatted = healthSummary.hourly.time && new Date(healthSummary.hourly.time).toLocaleString();
    const daily_time_formatted = healthSummary.daily.time && new Date(healthSummary.daily.time).toLocaleString();

    const linkOrTitle = (isFailed: boolean, title: string, healthCheckId: string, healthCheckFrequency: string, healthCheckType: string) => {
      return isFailed ? <Link to={`/customers/${currentCustomerId}/healthchecks/${healthCheckId}?freq=${healthCheckFrequency}&type=${healthCheckType}`} className="text-blue-600 hover:text-blue-700 ml-2">{title}</Link>
        : <span className="ml-2">{title}</span>;
    }

    return (
      <div className="flex flex-col h-full space-y-2 p-4">
        <span className="text-sm font-semibold text-gray-600 self-center">{currentCustomerData["enabled"]? "Healthchecks": "Healthchecks Are Disabled"}</span>
        <span className="text-sm font-semibold text-gray-600">Hourly</span>
        {hourly_time_formatted && <span className="text-sm text-gray-600">Last updated: {hourly_time_formatted}</span>}
        <div className="flex items-center text-sm font-medium">
          {statusIconHealthUnknown(healthSummary.hourly.automation_rules)}
          <div className="flex flex-col">
            
          {linkOrTitle(healthSummary.hourly.automation_rules === "Unhealthy", "Automation Rules", healthSummary.hourly.id, "hourly", "automation_rules")}       
            {healthSummary.hourly.automation_rules === "Unknown" && <span className="text-sm text-gray-400 ml-2">No runs observed since last check</span>}       
          </div>
        </div>
        <div className="flex items-center text-sm font-medium">
          {statusIconHealthUnknown(healthSummary.hourly.logic_apps)}
          <div className="flex flex-col">
            
          {linkOrTitle(healthSummary.hourly.logic_apps === "Unhealthy", "Logic Apps", healthSummary.hourly.id, "hourly", "logic_apps")}
            {healthSummary.hourly.logic_apps === "Unknown" && <span className="text-sm text-gray-400 ml-2">No runs observed since last check</span>}
          </div>
        </div>
        <div className="flex items-center text-sm font-medium">
          {statusIcon(healthSummary.hourly.data_connectors)}
          <div className="flex flex-col">
            
          {linkOrTitle(healthSummary.hourly.data_connectors === "Unhealthy", "Data Connector Activity", healthSummary.hourly.id, "hourly", "data_connector_activity")}
            {healthSummary.hourly.data_connectors === "Unknown" && <span className="text-sm text-gray-400 ml-2">No data observed since last check</span>}
          </div>
        </div>
        <div className="flex items-center text-sm font-medium">
          {statusIcon(healthSummary.hourly.data_connector_failures)}
          <div className="flex flex-col">
            
          {linkOrTitle(healthSummary.hourly.data_connector_failures === "Unhealthy", "Data Connector Failures", healthSummary.hourly.id, "hourly", "data_connector_failures")}
            {healthSummary.hourly.data_connector_failures === "Unknown" && <span className="text-sm text-gray-400 ml-2">No data observed since last check</span>}
          </div>
        </div>
        <span className="text-sm font-semibold text-gray-600">Daily</span>
        {daily_time_formatted && <span className="text-sm text-gray-600">Last updated: {daily_time_formatted}</span>}
        <div className="flex items-center text-sm font-medium">
          {statusIcon(healthSummary.daily.data_connector_zero_logs)}
          <div className="flex flex-col">
            {linkOrTitle(healthSummary.daily.data_connector_zero_logs === "Unhealthy", "Data Connector Activity", healthSummary.daily.id, "daily", "data_connector_zero_logs")}
            {healthSummary.daily.data_connector_zero_logs === "Unknown" && <span className="text-sm text-gray-400 ml-2">No data observed since last check</span>}
          </div>
        </div>
        <span className="flex w-full h-full justify-end items-end">
          <Link to={`/customers/${currentCustomerId}/healthchecks`} className="text-ui-electricGreen hover:text-ui-neonGreen">View all</Link>
        </span>
      </div>
    );
  };

  const renderReports = () => {
    const newReportsIndicator = reportInfo.unpublished_records > 0
      ? <MdOutlineErrorOutline className="text-yellow-500" size={24} />
      : null;

    return (
      <div className="flex flex-col space-y-2 p-4">
        <span className="flex justify-between items-center text-sm font-semibold text-gray-600 self-center">{currentCustomerData["reporting_enabled"] && currentCustomerData["enabled"]? "Reports": "Reporting is Disabled"}</span>
        {isValidCustomer ? (
          <div>
            <span className="text-sm">Total reports: {reportInfo.total_records}</span>
            <div className="flex justify-between items-center text-sm">
              <span>New reports: {reportInfo.unpublished_records}</span>
              {newReportsIndicator}
            </div>
            <span className="text-sm">Published reports: {reportInfo.published_records}</span>
            <div className="mt-2">
            {reportInfo.unpublished_records > 0 && (
              <Link to="/reportEditor" className="text-blue-600 hover:text-blue-700 text-sm">
                New reports available. Click to review.
              </Link>
            )}
            </div>
          </div>
        ) : (
          <span className="text-sm">No report available</span>
        )}
        <Link to="/reportView" className="text-blue-600 hover:text-blue-700 text-sm">
          View published reports
        </Link>
        <div hidden={!userRoles.includes(REPORT_ADMIN_ROLE) || !isValidCustomer}>
          <RoundButton
            text={currentCustomerData["reporting_enabled"] ? "Disable Reporting" : "Enable Reporting"}
            colour={currentCustomerData["reporting_enabled"] ? Theme.Colors.Error : Theme.Colors.Primary}
            extraStyle={"w-40 mt-3"}
            disabled={!userRoles.includes(REPORT_ADMIN_ROLE)}
            onClickAction={() => {
              dispatch(activateFullScreenPrompt("loading"))
              let toggleEnabledReqObj: ToggleCustomerEnabledInDbRequest = {
                  id: currentCustomerId as string,
                  toggle_reporting: true              
                }
                toggleCustomerEnabled(toggleEnabledReqObj)
              }} 
          />
          </div>       
      </div>
    );
  };

  const renderBaselineChangesWidget = () => {
    return (
      <div className={`flex flex-row items-center gap-5 transition-transform p-2 rounded-lg justify-between bg-gray-200`}>
        <span className="text-sm text-gray-700">
          A new baseline has been generated and auto-accepted for this customer
          in the last 7 days.{" "}
          {currentHistoricalBaseline ? (
            <Link
              to={`/customers/${currentCustomerId}/baselines/${currentBaselineId ?? ""}`}
              className="text-blue-600 hover:text-blue-700 hover:font-medium text-sm"
              onClick={() => dispatch(setDisplayBaselineDifferences(true))}
            >
              See the latest changes.
            </Link>
          ) : (
            <span className="text-sm text-yellow-600">Loading the latest changes...</span>
          )}
        </span>
      </div>
    );
  }

  const approveIgnore = (entry: IgnoredResource) => {
    dispatch(setIgnoredResourceApproved({entry: entry, approve: true}));
    approveIgnoredResource(msalInstance, currentCustomerId as string, entry.resource_name, entry.resource_type)
      .then(response => {
        dispatch(removeIgnoredResource(entry));
        dispatch(addIgnoredResource(response.data));

      }).catch(() => {
        dispatch(setIgnoredResourceApproved({entry: entry, approve: false}));
      });
  }

  const rejectIgnore = (entry: IgnoredResource) => {
    const resourceToRemove = allIgnoredResources.find((r) => r.resource_name === entry.resource_name && r.resource_type === entry.resource_type);
        dispatch(removeIgnoredResource(resourceToRemove));
        
        removeResourceFromIgnoreList(msalInstance, currentCustomerId as string, entry.resource_name, entry.resource_type)
          .catch(() => {
            console.log("Failed to remove entry from ignore list: ", entry.resource_name)
          });
  }

  const unignore_entry = (entry: IgnoredResource) => {
    const resourceToRemove = allIgnoredResources.find((r) => r.resource_name === entry.resource_name && r.resource_type === entry.resource_type);
    dispatch(removeIgnoredResource(resourceToRemove));
    
    removeResourceFromIgnoreList(msalInstance, currentCustomerId as string, entry.resource_name, entry.resource_type)
      .then(response => {
        console.log("Successfully removed entry from ignore list: ", entry.resource_name)
      }).catch(() => {
        console.log("Failed to remove entry from ignore list: ", entry.resource_name)
      });
  };
  
  const ignoredResourcesSorted = allIgnoredResources.slice(0)
  ignoredResourcesSorted.sort((a, b) => a.approved != b.approved ? (a.approved ? 1 : -1) : (a.resource_name > b.resource_name ? 1 : -1))

  const renderIgnoreListWidget = () => {
    return (
      <div className="flex flex-col space-y-2">        
        <span className="text-sm font-semibold text-gray-600 self-center">Resources ignored in health checks</span>
        {isValidCustomer ? (
          <div className="flex flex-col">
            {ignoredResourcesSorted.length > 0 ? (
              <div className="flex flex-wrap pl-5">
                {ignoredResourcesSorted.map((resource, index) => (
                  <div key={index} className="flex flex-col text-sm text-gray-500 pt-4 px-4 pb-2 bg-white shadow-md rounded-lg m-2 items-center justify-between">
                    {confirmingIgnore === resource && <ConfirmActionModal title="Approve ignoring resource" description={"This resource will continue to be monitored but will not appear in summaries or generate notifications."} closeForm={() => setConfirmingIgnore(undefined)} onSubmit={() => approveIgnore(resource)} />}
                    {rejectingIgnore === resource && <ConfirmActionModal title="Reject ignoring resource" description={"Rejecting this request means this resource will continue to appear in summaries and generate notifications for failures."} closeForm={() => setRejectingIgnore(undefined)} onSubmit={() => rejectIgnore(resource)} />}
                    {openRemoveIgnoredResourceForm === resource && <RemoveIgnoredResourceForm resourceName={resource.resource_name} description={resource.description} ignoredUser={resource.username} closeForm={() => setOpenRemoveIgnoredResourceForm(undefined)} onSubmit={() => unignore_entry(resource)} />}         
                    {currentCustomerId && approvingIgnoreModal === resource && <PendingIgnoreModal ignoredResource={resource} customerId={currentCustomerId} 
                      onClose={() => {
                        setApprovingIgnoreModal(undefined);
                        const newPath = `/customers/${currentCustomerId}`;
                        navigate(newPath, { replace: true });
                      }}/>}
                    <div className="flex flex-col m-2">
                      <span className="pb-2 font-semibold">{resource.resource_name}    </span>
                      <span>Type: {resource.resource_type}    </span> 
                      <span>Ignored by: {resource.username}    </span>      
                      {resource.expiry && <span>Expires on {new Date(resource.expiry).toLocaleDateString()}</span>}
                      <span>Reason: {resource.description}    </span> 
                      {!resource.approved && 
                      <div className="flex flex-col">
                        <span className="text-red-500 pt-4">Pending approval</span>
                        <div className="pb-2">
                          {!resource.approved && currentCustomerId && userRoles.includes(REPORT_ADMIN_ROLE) && <PendingIgnoreComponent ignoredResource={resource} customerId={currentCustomerId} message={""}/>}
                        </div>
                      </div>
                      }
                      </div>
                      {userRoles.includes(REPORT_ADMIN_ROLE) &&
                        <span><button className={"text-blue-500"} onClick={() => setOpenRemoveIgnoredResourceForm(resource)}>Remove from ignore list</button></span>
                      }
                  </div>
                ))}
              </div>
            ) : (
              null
            )}
          </div>
        ) : (
          <span className="text-sm">No Sentinel environment available</span>
        )}
      </div>
    );
  }

  const renderSentinelEnvironment = () => {
    let isOlderThanSevenDays = false;
    if (baselineInfo.new_accepted_record_date) {
      const date = new Date(baselineInfo.new_accepted_record_date);
      const currentDate = new Date();
      const differenceInDays = (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
      if (differenceInDays > 7) isOlderThanSevenDays = true;
    }
    return (
      <div className="flex flex-col space-y-2 p-4">
        <span className="text-sm font-semibold text-gray-600 self-center">Environment</span>
        {isValidCustomer ? (
          <div className="flex flex-col gap-2">
            <span className="text-sm">
              <Link
                to={`/customers/${currentCustomerId}/baselines/${currentBaselineId ?? ""}`}
                className="text-blue-600 hover:text-blue-700 hover:font-medium text-sm"
                >
                Click here {" "}
              </Link>
                to view current and historical Sentinel environment for this customer
            </span>
            {baselineInfo.total_records > 1 &&
              baselineInfo.new_accepted_record > 0 &&
              !isOlderThanSevenDays &&  
              renderBaselineChangesWidget()}
              {/* {renderBaselineChangesWidget()} */}
          </div>
        ) : (
          <span className="text-sm">No Sentinel environment available</span>
        )}
      </div>
    );
  };

  const renderDashboard = () => {
    if (approveIgnoredResourceName && allIgnoredResources.length === 0) {
      return <div className="flex flex-col h-full w-full items-center">
        <AiOutlineLoading3Quarters className='animate-spin h-12 w-12 text-slate-100 mb-6'/>
      </div>
    }
    
    return (
      <div className={`${ReportPageStyle} w-full flex flex-wrap justify-center gap-2`}>
        {/* Dashboard tiles */}
        <div className={`flex-auto rounded-md bg-white shadow-sm p-1 ${Theme.Colors.Shadow}`}>
          {renderCustomerInfo()}
        </div>
        {
          currentCustomerData && currentCustomerData.products && currentCustomerData.products.some(p => p.id === "ice_imhr") &&
          <div className={`flex-auto rounded-md bg-white shadow-sm p-1 ${Theme.Colors.Shadow}`}>
            {renderHealthchecks()}
          </div>
        }
        <div className={`flex-auto rounded-md bg-white shadow-sm p-1 ${Theme.Colors.Shadow}`}>
          {renderReports()}
        </div>
        <div className={`flex-auto rounded-md bg-white shadow-sm p-1 ${Theme.Colors.Shadow}`}>
          {renderSentinelEnvironment()}
        </div>
        {allIgnoredResources.length > 0 && <div className={`flex-auto rounded-md bg-white shadow-sm p-1 ${Theme.Colors.Shadow}`}>
          {renderIgnoreListWidget()}
        </div>}
      </div>
    );
  }

  if (userMode === "editor") {
    return (
      <div className={`flex ${Theme.Colors.DarkWebBg}`}>
        <MenuBar onPage="customers" />
        <div
          className={`w-full flex flex-col items-center min-h-screen pl-44 bg-cover`}
          style={{ backgroundImage: `url(${CustomerBackground})` }}
        >
          <TopNavBar />
          <span className={`place-self-start flex pl-16 pt-4`}>
            <Breadcrumb pathSegments={pathSegments} startFrom="customers" />
          </span>
          <div className={`w-full flex flex-col items-center min-h-screen p-4`}>
            <Routes>
              <Route element={renderDashboard()} path={""} />
              <Route element={<BaselineView />} path={"baselines"} />
              {allCustomersID.length > 0 &&
                (Object.keys(baselines).length > 0 || currentAcceptedBaseline) &&
                getSyncTimeString() && (
                  <Route element={<BaselineView />} path={"baselines/:baselineId"} />
                )}
              <Route element={<HealthCheckView />} path={"healthchecks"} />
              {allCustomersID.length > 0 && (
                <Route
                  element={<HealthCheckView />}
                  path={"healthchecks/:healthCheckId"}
                />
              )}
            </Routes>
          </div>
        </div>
      </div>
    );
  } else {
    return <PageNotAccessable />;
  }
};

export default CustomerPage;

import { useAppDispatch, useAppSelector } from "../redux/hook";
import { PageType, SyncTimeDataType, clearCurrentReport, clearEditedSections, deleteFromEditedSections, setBaselines, setCurrentAcceptedBaseline, setCurrentControlBoard, setCurrentEditor, setDisplayBaselineDifferences, setCurrentHistoricalBaseline, setInvalidReports, setPreview, setValidReports, setHistoricalBaselineIds, addReportsToType } from "../redux/dataSlice";
import { getFromPath, useCurrentReportInInvalidReports } from "../service/dataLogic";
import { setCurrentCustomerId, setCurrentReportId, setCurrentReportType, updateData, setLastSyncTime, setCurrentSentinelWorkspace } from "../redux/dataSlice";
import RoundButton from "./utility/RoundButton";
import DefendLogo from "../resource/DEFENDLogo-White.png"
import { useMsal } from "@azure/msal-react";
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import { reGenerateReport, generateReportFile, getBauBaselines, getDbReports, getServices, getSherlockMetrics, putReportToDb, getReportsByType } from "../service/apiAccessLogic";
import { getSyncTimeString, generateCustomerOption, generateTypeOption, generateReportOption, generateStatus, generateBaselineStatus, generateWorkspaceOptions, generateAllCustomerOption, generateHistoricalBaselineOptions, parseReportFileName } from "../service/menuLogic";
import { IMReports } from "../models/ReportModel";
import { useNavigate } from "react-router-dom";
import { BaselineObjects } from "../models/BaselineModel";
import { setIOCBlacklist, setPagedIOCsDisplay, setPagedSightingsDisplay, setStreamSightingsDisplay } from "../redux/sherlockSlice";
import DownloadBackupSelector from "../components/backups/DownloadBackupSelector" ;
import Theme from "../Theme";
import { useState } from "react";
import { REPORT_ADMIN_ROLE } from "../models/EditorModel";
import { CreateCustomerMainForm } from "./forms/createCustomerForm/CreateCustomerMainForm";
import { UpdateCustomerMainForm } from "./forms/updateCustomerForm/UpdateCustomerMainForm";
import { setAllowedServices, setFormType } from "../redux/formSlice";
import { msalInstance } from "..";

function MenuBar({ onPage }: { onPage: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const userMode = useAppSelector((state) => state.user.userMode)
  const userRoles = useAppSelector(state => state.user.userRoles)

  const allCustomersID = useAppSelector(state => state.data.allCustomersID)
  const invalidReports = useAppSelector((state) => state.data.invalidReports)
  const validReports = useAppSelector((state) => state.data.validReports)

  const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId)
  const currentSentinelWorkspace = useAppSelector((state) => state.data.currentSentinelWorkspace)
  const currentCustomerData = useAppSelector((state) => state.customer.customer_info)
  const currentReportType = useAppSelector((state) => state.data.currentReportType)
  const currentReportId = useAppSelector((state) => state.data.currentReportId)
  const editedSections = useAppSelector((state) => state.data.editedSections)
  const currentReport = useAppSelector((state) => state.data.currentReport)
  const [exportFileType, setExportFileType] = useState("" as string)
  
  const pathToCurrentSection = useAppSelector((state) => state.data.currentEditor)
  const editorSyncTime = useAppSelector((state) => state.data.editorSyncTime)
  const reportSyncTime = useAppSelector((state) => state.data.reportSyncTime)
  const baselineSyncTime = useAppSelector((state) => state.data.baselineSyncTime)
  const previewMode = useAppSelector((state) => state.data.preview)
  const currentReportInInvalidReports = useCurrentReportInInvalidReports()  
  
  const baselines = useAppSelector((state) => state.data.baselines)
  const historicalBaselineIds = useAppSelector((state) => state.data.historicalBaselineIds)
  const currentAcceptedBaseline = useAppSelector((state) => state.data.currentAcceptedBaseline)
  const currentHistoricalBaseline = useAppSelector((state) => state.data.currentHistoricalBaseline)

  const streamSightingsDisplay = useAppSelector(state => state.sherlock.streamSightingsDisplay)
  const pagedSightingsDisplay = useAppSelector(state => state.sherlock.pagedSightingsDisplay)
  const pagedIOCsDisplay = useAppSelector(state => state.sherlock.pagedIOCsDisplay)
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const displayBaselineDifferences = useAppSelector(state => state.data.displayBaselineDifferences)

  const allowedServices = useAppSelector((state) => state.form.allowedServices.services)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const renderEditorButtonSection = () => {
    return (
      <div className="w-full h-full justify-between flex flex-col items-center">  
        <div>
          {/*REVERT SECTION */}
          <RoundButton text={"Revert Section"} colour={Theme.Colors.Error} extraStyle={"w-40 mt-3"} 
            disabled={editedSections.length === 0 || userRoles.includes("read_only")} 
            onClickAction={() => {
              dispatch(updateData({'newValue':getFromPath(currentReportInInvalidReports,pathToCurrentSection),'path':pathToCurrentSection}))
              dispatch(deleteFromEditedSections(pathToCurrentSection))
          }} />

          {/*SAVE AND SYNC */}
          <RoundButton text={"Save & Sync"} colour={`${Theme.Colors.Primary}`} extraStyle={"w-40 mt-3"} 
            disabled={editedSections.length === 0 || userRoles.includes("read_only")} 
            onClickAction={async ()=> {
              if (currentReport === null) return 

              dispatch(activateFullScreenPrompt("loading"))
              let isError = false
              await putReportToDb(instance, currentReport).catch(()=>{
                  dispatch(activateFullScreenPrompt("error"));
                  isError = true
              })
              if(isError) { return }
              await getDbReports(instance, false).then((response)=>{
                  dispatch(setInvalidReports(response.data))
                  dispatch(setLastSyncTime({time: getSyncTimeString(), type: "editor"}))
              }).catch(()=>{
                  dispatch(activateFullScreenPrompt("error"));
                  isError = true
              })
              if(isError) { return }
              dispatch(clearEditedSections())
              dispatch(activateFullScreenPrompt("false"));
              
          }} />
          
          {/*PREVIEW */}
          <RoundButton text={"Preview"} colour={`${Theme.Colors.Primary}`} extraStyle={"w-40 mt-3"} disabled={currentReportId === ""} onClickAction={() => {
              dispatch(setPreview(previewMode === true ? false:true))
          }} />                
        </div>           

        <div>
        {/* Re generate customer report */}
        <RoundButton  text={"Regenerate Report"} colour={Theme.Colors.Error} 
            extraStyle={`w-40 mt-3 ${userRoles.includes(REPORT_ADMIN_ROLE) ? "block":"hidden"}`} 
            disabled={currentCustomerId === "" || currentReportType === "" || currentReportId === "" || !userRoles.includes(REPORT_ADMIN_ROLE)} 
            onClickAction={async ()=> { 
                dispatch(activateFullScreenPrompt("loading"))
                await reGenerateReport(msalInstance, currentCustomerId, currentReportType, currentReportId)
                  .catch((error) => {
                  if (error.response && error.response.data) {
                    console.log(`error: ${error.response.data}`)
                    dispatch(activateFullScreenPrompt("error"))
                  }
                })

                dispatch(clearCurrentReport())
                
                await getDbReports(instance, false)
                  .then( (response)=>{
                    dispatch(setInvalidReports(response.data))
                    let syncTimeDataType:SyncTimeDataType = "editor"
                    dispatch(setLastSyncTime({time: getSyncTimeString(), type: syncTimeDataType}))
                    dispatch(activateFullScreenPrompt("false"))
                  })
                  .catch(()=>{
                    dispatch(activateFullScreenPrompt("error"))
                })
        }}/>  

          {/*Finalise */}
          <RoundButton text={currentReportId === "" 
                        ? "Finalise"
                        : (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true) 
                        ? userRoles.includes(REPORT_ADMIN_ROLE) 
                        ? "Allow Editing" 
                        : "Finalise" : "Finalise"
                      } 
                      colour={Theme.Colors.Error} extraStyle={"font-bold border-2 border-dashed border-amber-400 w-40 h-10 mt-3"} 
                      disabled={currentReportId === "" || userRoles.includes("read_only") || (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true && !userRoles.includes(REPORT_ADMIN_ROLE))} 
                      onClickAction={async () => {
                          // update report finalised status
                          let report = JSON.parse(JSON.stringify(currentReport))
                          let updateType = "finalise"
                          if (report["finalised"] && report["finalised"] === true && userMode === "editor" && userRoles.includes(REPORT_ADMIN_ROLE)) {
                              report["finalised"] = false
                              updateType = "unfinalise"
                          } else {
                              report["finalised"] = true
                          }
                          const isMonthlyReport = report["report_id"].includes("month")
                          const warning = isMonthlyReport ? "monthlyUndeterminedAlerts" : "weeklyUndeterminedAlerts"

                          dispatch(activateFullScreenPrompt("loading"))
                          //push and pull
                          let isError = false
                          await putReportToDb(instance, report, updateType).catch((e) => {
                            isError = true;
                            if (e.response.status === 422) {
                              dispatch(activateFullScreenPrompt(warning));
                            } else {
                              dispatch(activateFullScreenPrompt("error"));
                            }
                          });
                          if (isError){ return }
                          await getDbReports(instance, false).then( (response)=>{
                              dispatch(setInvalidReports(response.data))
                              dispatch(setLastSyncTime({time: getSyncTimeString(), type: "editor"}))
                          }).catch(()=>{
                              dispatch(activateFullScreenPrompt("error"))
                              isError = true
                          })
                          if (isError){ return }
                          //reset UI indicators
                          dispatch(clearCurrentReport())
                          dispatch(clearEditedSections())
                          if (report["finalised"] === true) {
                              dispatch(activateFullScreenPrompt("finalised"))
                          } else {
                              dispatch(activateFullScreenPrompt("unfinalised"))
                          } 
          }} /> 

          {/*Publish */}
          <RoundButton text={"Publish"} colour={Theme.Colors.Error} extraStyle={" font-bold border-2 border-dashed border-amber-400 w-40 h-10 mt-3"} 
              disabled={currentReportId===""
                  || userMode !== "editor"
                  || userRoles.includes("read_only")
                  || (!userRoles.includes(REPORT_ADMIN_ROLE) && currentReportId.includes("month"))
                  || (currentReport !== null && currentReport["finalised"] === undefined) 
                  || (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] !== true)} 
              onClickAction={async ()=>{
                  //create report marked valid
                  let report = JSON.parse(JSON.stringify(currentReport))
                  report["valid"] = true
                  const isMonthlyReport = report["report_id"].includes("month")
                  const warning = isMonthlyReport ? "monthlyUndeterminedAlerts" : "weeklyUndeterminedAlerts"

                  dispatch(activateFullScreenPrompt("loading"))
                  //push and pull
                  let isError = false
                  await putReportToDb(instance, report, "publish").catch((e) => {
                    isError = true;
                    if (e.response.status === 422) {
                      dispatch(activateFullScreenPrompt(warning));
                    } else {
                      dispatch(activateFullScreenPrompt("error"));
                    }
                  });
                  if (isError){ return }
                  // pull updated invalid reports
                  await getDbReports(instance, false).then( (response)=>{
                      dispatch(setInvalidReports(response.data))
                      dispatch(setLastSyncTime({time: getSyncTimeString(), type: "editor"}))
                  }).catch(()=>{
                      console.error("Failed to pull invalid reports")
                  })
                  // pull updated valid reports
                  await getDbReports(instance, true).then( (response)=>{
                    dispatch(setValidReports(response.data))
                    dispatch(setLastSyncTime({time: getSyncTimeString(), type: "report"}))
                  }).catch(()=>{
                      console.error("Failed to pull valid reports")
                  })
                  //reset UI indicators
                  dispatch(clearCurrentReport())
                  dispatch(clearEditedSections())
                  dispatch(activateFullScreenPrompt("published"));
                  setDataLoaded(false)
          }} />                                   
        </div>
      </div>
    )
  }

  const renderReportSelection = (reports:IMReports, pageType:PageType) => {
    const valid = pageType === "editor" ? false : true;
    const lastSyncTime = pageType === "editor" ? editorSyncTime : reportSyncTime;
    return (
      <div className="w-full h-fit flex flex-col items-center">
        {/*STATUS WINDOW*/}
        <div className="w-40 pb-2 mb-1 rounded-md shadow-inner">
            {generateStatus(reports, currentCustomerId, lastSyncTime, pageType)}
        </div>

        {/*SELECT COMPANY*/}
        <select className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50" value={currentCustomerId} disabled={editedSections.length !== 0} onChange={ (event)=> {
            //disable control board and editor
            dispatch(setCurrentEditor(""))
            dispatch(setCurrentControlBoard(""))
            //select customer, CLEAR selection for type and report ID
            dispatch(setCurrentCustomerId(event.target.value));
            dispatch(setCurrentReportType(""));
            dispatch(setCurrentReportId({id: "", type: pageType}));
            setDataLoaded(false)
        }}>
            <option value={""} disabled={true}>-Select Company-</option>
            {generateCustomerOption(reports)}
        </select>

        {/*SELECT TYPE*/}
        <select className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50" value={currentReportType} disabled={(currentCustomerId === "")||(editedSections.length !== 0)} onChange={ (event) => {
            //disable control board and editor
            dispatch(setCurrentControlBoard(""))
            dispatch(setCurrentEditor(""))
            //select type, CLEAR report id
            dispatch(setCurrentReportType(event.target.value))
            dispatch(setCurrentReportId({id: "", type: pageType}))
            setExportFileType("")
            setDataLoaded(false)
        }}>
            <option value={""} disabled={true}>-Select Type-</option>
            {generateTypeOption(reports, currentCustomerId)}
        </select>

        {/*SELECT REPORT*/}
        <select className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50" value={currentReportId} disabled={(currentReportType === "")||(editedSections.length !== 0)} onChange={ (event) => {
            dispatch(setCurrentReportId({id:event.target.value, type:pageType}))
            dispatch(setCurrentControlBoard(currentReportType))
            setExportFileType("")
        }}>
            <option value={""} disabled={true}>-Select Report-</option>
            {currentReportType !== "" && generateReportOption(reports, currentCustomerId, currentReportType)}
        </select>

        {/*REFRESH DATA BUTTON*/}
        <RoundButton text={"Refresh"} colour={`${Theme.Colors.Primary}`}  extraStyle={" w-40 mt-3"} disabled={editedSections.length !== 0} onClickAction={async ()=>{
            dispatch(clearCurrentReport())
            dispatch(activateFullScreenPrompt("loading"))
            getDbReports(instance, valid)
              .then( (response)=>{
                if (valid) {dispatch(setValidReports(response.data))}
                else {dispatch(setInvalidReports(response.data))}

                let syncTimeDataType:SyncTimeDataType = pageType === "editor" ? "editor":"report"
                dispatch(setLastSyncTime({time: getSyncTimeString(), type: syncTimeDataType}))
                setDataLoaded(false)
                dispatch(activateFullScreenPrompt("false"))
              })
              .catch(()=>{
                dispatch(activateFullScreenPrompt("error"))
            })
        }} /> 

        {/*LOAD MORE REPORTS BUTTON*/}
        <RoundButton text={"Load More Reports"} colour={`${Theme.Colors.Primary}`}  extraStyle={" w-40 mt-3"} disabled={currentCustomerId === "" || currentReportType === "" || dataLoaded} onClickAction={async ()=>{
            dispatch(activateFullScreenPrompt("loading"))
            
            const reportsInType = Object.entries(reports[currentCustomerId]).filter(([key, _]) => key === currentReportType).map(([_, value]) => value)[0]
            const offset = Object.keys(reportsInType).length

            getReportsByType(instance, valid, currentCustomerId, currentReportType, offset)
              .then( (response)=>{
                dispatch(addReportsToType({valid: valid, reports: response.data}))
                if (response.data.length < 10) {
                  setDataLoaded(true)
                }
                let syncTimeDataType:SyncTimeDataType = pageType === "editor" ? "editor":"report"
                dispatch(setLastSyncTime({time: getSyncTimeString(), type: syncTimeDataType}))
                dispatch(activateFullScreenPrompt("false"))
              })
              .catch(()=>{
                dispatch(activateFullScreenPrompt("error"))
            })
        }} />                    
      </div>
    )
  }

  const [openCustomerCreationForm, setOpenCustomerCreationForm] = useState(false);
  const [openCustomerUpdateForm, setOpenCustomerUpdateForm] = useState(false);
  const handleCloseAddCustomerForm = () => setOpenCustomerCreationForm(false);
  const handleCloseAddServiceForm = () => setOpenCustomerUpdateForm(false);
  const renderCustomerPageSection = () => {
    return (
      <div className="w-full h-fit flex flex-col items-center">
        <select 
            className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50" 
            value={currentCustomerId} 
            disabled={allCustomersID.length === 0} 
            onChange={(event)=> {
              dispatch(setCurrentCustomerId(event.target.value))
              dispatch(setCurrentSentinelWorkspace(""))
              dispatch(setDisplayBaselineDifferences(false));
              dispatch(setCurrentHistoricalBaseline(null));
              dispatch(setHistoricalBaselineIds(null));
            }
          }
        >
          <option value={""} disabled={true}>-Select company-</option>
          {generateAllCustomerOption(allCustomersID)}
        </select>

        {userMode === "editor" && (
          <div>
            {/* ADD NEW CUSTOMER */}
            <RoundButton
              text={"Add New Customer"}
              colour={Theme.Colors.Primary}
              extraStyle={"w-40 mt-3"}
              disabled={allCustomersID.length === 0 || userRoles.includes("read_only")}
              onClickAction={() => {
                dispatch(setFormType("CustomerCreation"));
                setOpenCustomerCreationForm(true);
                getServices(msalInstance)
                  .then((response) => {
                    dispatch(setAllowedServices(response.data));
                  })
                  .catch((e) => {
                    console.log("Error getting list of services: " + e);
                    dispatch(activateFullScreenPrompt("error"));
                  })
              }}
            />
            <CreateCustomerMainForm
              openForm={openCustomerCreationForm}
              closeForm={handleCloseAddCustomerForm}
            />

            {/* MANAGE EXISTING CUSTOMERS */}
            <RoundButton
              text={"Manage An Existing Customer"}
              colour={Theme.Colors.Primary}
              extraStyle={"w-40 mt-3"}
              disabled={allCustomersID.length === 0 || userRoles.includes("read_only")}
              onClickAction={() => {
                if (allowedServices[0].name === "") {
                  getServices(msalInstance)
                  .then((response) => {
                    dispatch(setAllowedServices(response.data));
                  })
                  .catch((e) => {
                    console.log("Error getting list of services: " + e);
                    dispatch(activateFullScreenPrompt("error"));
                  })                  
                }
                dispatch(setFormType("CustomerManagement"));
                setOpenCustomerUpdateForm(true);
                getServices(msalInstance)
                  .then((response) => {
                    dispatch(setAllowedServices(response.data));
                  })
                  .catch((e) => {
                    console.log("Error getting list of services: " + e);
                    dispatch(activateFullScreenPrompt("error"));
                  });
              }}
            />
            <UpdateCustomerMainForm
              openForm={openCustomerUpdateForm}
              closeForm={handleCloseAddServiceForm}
            />
          </div>
        )}
      </div>
    );
  }
    
  const renderBaselinePageSection = (baselines: BaselineObjects) => {
    return (
      <div className="w-full h-fit flex flex-col items-center">
        {/*STATUS WINDOW*/}
        <div className="w-40 px-2 py-2 my-1 rounded-md shadow-inner">
          {generateBaselineStatus(baselineSyncTime)}
        </div>

        {/*SELECT COMPANY*/}
        <select
          className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
          value={currentCustomerId}
          disabled={!baselines || Object.keys(baselines).length === 0}
          onChange={(event) => {
            //select customer, set baseline to the current accepted baseline
            dispatch(setCurrentCustomerId(event.target.value));
            dispatch(setDisplayBaselineDifferences(false));
            dispatch(setCurrentHistoricalBaseline(null));
            dispatch(setHistoricalBaselineIds(null));
            navigate(`/customers/${event.target.value}/baselines`);

            const firstWorkspace = Object.keys(baselines[event.target.value])[0]
            if (firstWorkspace) {
              dispatch(setCurrentSentinelWorkspace(firstWorkspace))
              dispatch(setCurrentAcceptedBaseline(baselines[event.target.value][firstWorkspace]));
            } else {
              dispatch(setCurrentSentinelWorkspace(""))
              dispatch(setCurrentAcceptedBaseline(null))
            }
          }}
        >
          <option value={""} disabled={true}>-Select company-</option>
          {generateCustomerOption(baselines)}
        </select>

        {/*SELECT WORKSPACE*/}
        <select
          className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
          value={currentSentinelWorkspace ? currentSentinelWorkspace : ""}
          disabled={!baselines || !baselines[currentCustomerId] || (baselines[currentCustomerId] && Object.keys(baselines[currentCustomerId]).length === 0)}
          onChange={(event) => {
            dispatch(setCurrentSentinelWorkspace(event.target.value));
            dispatch(setCurrentAcceptedBaseline(baselines[currentCustomerId][event.target.value]));
            dispatch(setCurrentHistoricalBaseline(null));
            dispatch(setHistoricalBaselineIds(null));
            dispatch(setDisplayBaselineDifferences(false));
          }}
        >
          <option value={""} disabled={true}>-Select workspace-</option>
          {currentCustomerData && generateWorkspaceOptions(currentCustomerData, baselines)}
        </select>

        {/*CURRENT BASELINE*/}
        <select
          className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
          aria-readonly={true}
          value={currentAcceptedBaseline ? currentAcceptedBaseline.id : ""}
          disabled={!baselines || !baselines[currentCustomerId] || (baselines[currentCustomerId] && Object.keys(baselines[currentCustomerId]).length === 0) || !currentAcceptedBaseline || Object.entries(currentAcceptedBaseline).length === 0}
        >
          <option value={""} disabled={true}>-Current baseline-</option>
          <option value={currentAcceptedBaseline ? currentAcceptedBaseline.id : ""} disabled={false}>{currentAcceptedBaseline ? currentAcceptedBaseline.id : ""}</option>
        </select>

        {/*SELECT HISTORICAL BASELINE*/}
        <select
          className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
          value={currentHistoricalBaseline ? currentHistoricalBaseline.id : ""}
          disabled={!historicalBaselineIds?.length || !currentAcceptedBaseline || !Object.entries(currentAcceptedBaseline)?.length}
          onChange={(event) => {
            if (event.target.value === "") { 
              dispatch(setDisplayBaselineDifferences(false)) 
              dispatch(setCurrentHistoricalBaseline(null));
            } else {              
              // call API to get historical baseline data
              dispatch(activateFullScreenPrompt("loading"));
              getBauBaselines(msalInstance, "baseline", event.target.value)
                .then((response) => {
                  if (response.status === 200) {
                    dispatch(setCurrentHistoricalBaseline(response.data));
                    dispatch(setDisplayBaselineDifferences(true));
                  }
                  dispatch(activateFullScreenPrompt("false"));
                })
                .catch(() => {
                  console.log("Error loading a specific historical baseline");
                });
            }
          }}
        >
          <option value={""} disabled={false}>-Select historical baseline-</option>
          {currentCustomerData && generateHistoricalBaselineOptions(historicalBaselineIds)}
        </select>

        {/*SHOW CHANGES COMPARISON*/}
        <RoundButton
          text={`${displayBaselineDifferences ? "Hide" : "Show"} Comparison`} 
          colour={Theme.Colors.Primary}
          extraStyle={"w-40 mt-3"}
          disabled={!historicalBaselineIds || !Object.entries(historicalBaselineIds).length || !currentAcceptedBaseline || !Object.entries(currentAcceptedBaseline).length}
          onClickAction={() => {dispatch(setDisplayBaselineDifferences(!displayBaselineDifferences))}}
        />

        {/*REFRESH DATA BUTTON*/}
        <RoundButton
          text={"Refresh"}
          colour={`${Theme.Colors.Primary}`}
          extraStyle={"w-40 mt-3"}
          onClickAction={async () => {
            dispatch(setCurrentCustomerId(""));
            dispatch(activateFullScreenPrompt("loading"));
            getBauBaselines(instance, "accepted_baselines")
              .then((response) => {
                if (response.status === 200) { dispatch(setBaselines(response.data)) }
                dispatch(activateFullScreenPrompt("false"));
                dispatch(setLastSyncTime({
                    time: getSyncTimeString(),
                    type: "baseline",
                  })
                );
              })
              .catch(() => {
                dispatch(activateFullScreenPrompt("error"));
              });
          }}
        />

        {/* DOWNLOAD BACKUP */}
        <DownloadBackupSelector />
      </div>
    )
  }

  const renderReportExport = () => {
    if (currentReport === null) return
    
    return (
      <div className="w-full h-fit mt-3 flex flex-col items-center">
        <select 
            className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50" 
            value={exportFileType} 
            onChange={(event)=> {
              setExportFileType(event.target.value)
            }
          }
        >
          <option key="default" value={""} disabled={true}>-Select File Type-</option>
          <option key="png" value={"png"}>PNG</option>
          <option key="pdf" value={"pdf"}>PDF</option>
        </select>
        <RoundButton
          text={"Export"}
          colour={Theme.Colors.Primary}
          extraStyle={"w-40 mt-2"}
          disabled={currentReportId === "" || userRoles.includes("read_only") || exportFileType === ""}
          onClickAction={exportToFile}
        />
      </div>
    )
  }

  const exportToFile = () => {
    if (currentReport === null) return
    const customerName = getFromPath(currentReport, "content.company_name") as string
    const customerId = currentReport.customer_id as string
    const reportId = currentReport.report_id as string

    dispatch(activateFullScreenPrompt("loading"))

    const queryObj = {
      customerId: customerId,
      reportId: reportId,
      fileType: exportFileType
    }

    let mediaType = "application/pdf"
    if (exportFileType === "png") {
      mediaType = "image/png"
    }

    generateReportFile(instance, queryObj).then((response) => {
      const blob = new Blob([response.data], {type: mediaType})
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      const fileName = parseReportFileName(reportId, customerName) + "." + exportFileType
      link.download = fileName
      link.click()
      dispatch(activateFullScreenPrompt("false"))
    }).catch(err => {
      console.error(err)
      dispatch(activateFullScreenPrompt("error"))
    })
  }

  const renderSherlockSelection = () => {  
    return (
      <div className="w-full h-full flex flex-col items-center">
        {/* SIGHTINGS STREAM DISPLAY */}
        <RoundButton text={`${streamSightingsDisplay ? "Hide Sightings Stream" : "Show Sightings Stream"}`} 
          colour={`${streamSightingsDisplay ? Theme.Colors.Error : Theme.Colors.Primary}`} extraStyle={"w-40 mt-3"} 
          onClickAction={() => {
            dispatch(setStreamSightingsDisplay(!streamSightingsDisplay))
        }} /> 
        {/* SIGHTINGS HISTORY DISPLAY */}
        <RoundButton text={`${pagedSightingsDisplay ? "Hide Sightings History" : "Show Sightings History"}`} 
          colour={`${pagedSightingsDisplay ? Theme.Colors.Error : Theme.Colors.Primary}`} extraStyle={"w-40 mt-3"} 
          onClickAction={() => {
            dispatch(setPagedSightingsDisplay(!pagedSightingsDisplay))
        }} /> 
        {/* INDICATORS DISPLAY */}
        <RoundButton text={`${pagedIOCsDisplay ? "Hide IOCs" : "Show IOCs"}`} 
          colour={`${pagedIOCsDisplay ? Theme.Colors.Error : Theme.Colors.Primary}`} extraStyle={"w-40 mt-3"} 
          onClickAction={() => {
            dispatch(setPagedIOCsDisplay(!pagedIOCsDisplay))
        }} />                 
      </div>    
    )
  }

  const renderSherlockAdminButtons = () => {
    return (
      <div className=" w-full h-fit flex flex-col items-center">
        {/*REFRESH DATA BUTTON*/}
        <RoundButton
        text={"Refresh IOC Blacklist Table"}
        colour={`${Theme.Colors.Primary}`}
        extraStyle={"w-40 mt-3"}
        onClickAction={async () => {
          dispatch(activateFullScreenPrompt("loading"));
          await getSherlockMetrics(instance, "iocblacklist")
            .then((response) => {
              dispatch(setIOCBlacklist(response.data));
              dispatch(activateFullScreenPrompt("false"));
            }).catch(() => {dispatch(activateFullScreenPrompt("error"))})
        }}
      />
      </div>      
    )
  }

  return (
    <div className={`fixed flex flex-col items-center top-0 left-0 h-screen w-44 pl-4 pr-4 bg-transparent shadow-2xl ${Theme.Colors.Shadow}`}>
      <img src={DefendLogo} className="cursor-pointer w-16 h-16" alt="Defend logo" onClick={() => {navigate("/")}} />
        
        {
          {
            "home": <div className="pt-2 w-full h-full"></div>,
            "reportView": (
              <div className="pt-2 w-full h-full flex flex-col items-center">
                {renderReportSelection(validReports, "view")}
                {renderReportExport()}
              </div>
            ),
            "reportEditor": (
              <div className="pt-2 w-full h-full flex flex-col items-center">
                {renderReportSelection(invalidReports, "editor")}
                {renderEditorButtonSection()}
              </div>
            ),
            "customers": (
              <div className="pt-2 w-full h-full flex flex-col items-center">
                {pathSegments.includes("baselines")
                  ? renderBaselinePageSection(baselines)
                  : renderCustomerPageSection()}
              </div>
            ),
            "sherlock": (
              <div className="pt-2 w-full h-full flex flex-col items-center">
                {renderSherlockSelection()}
              </div>
            ),
            "sherlockAdmin": (
              <div className="pt-2  w-full h-full flex flex-col items-center">
                {renderSherlockAdminButtons()}
              </div>
            ),
          }[onPage]
        }

      {/*SIGN OUT*/}
      <RoundButton
        text={"Sign Out"}
        colour={Theme.Colors.DarkWebBg}
        extraStyle={"w-40 mt-3 mb-6"}
        disabled={editedSections.length !== 0}
        onClickAction={() => {
          instance.logoutRedirect();
        }}
      />
    </div>
  )
}

export default MenuBar
import { msalInstance } from "../../..";
import { CreateCustomerRequest } from "../../../models/FormRequestModel";
import { setAllCustomersID } from "../../../redux/dataSlice";
import { FormDataState, clearFormData } from "../../../redux/formDataSlice";
import { resetPageKey } from "../../../redux/formSlice";
import { activateFullScreenPrompt } from "../../../redux/fullScreenPromptSlice";
import { createCustomerInDb, getCustomer, getHealthCheckData } from "../../../service/apiAccessLogic";
import { ServicesConfig } from "../../../models/FormRequestModel";
import { addServicesToCustomer } from "../../../service/apiAccessLogic";
import generateServiceConfigList from "./handleServiceConfig";
import { setPageKey } from "../../../redux/formSlice";
import { FormEvent } from "react";
import { updateBaselineInfo, updateCustomerInfo, updateHealthCheckInfo, updateReportInfo } from "../../../redux/customerSlice";

const handleFormSubmit = async (
  e: FormEvent<HTMLFormElement>,
  dispatch: any,
  currentFormData: FormDataState,
  allCustomersID: string[]
) => {
  e.preventDefault();
  const customerId = currentFormData.id;
  dispatch(activateFullScreenPrompt("loading"));
  if(!allCustomersID.includes(customerId)) {
    let createCustomerReqObj: CreateCustomerRequest = {
      customer: {
        id: customerId,
        company_name: currentFormData.company_name,
        tid: currentFormData.tid,
        is_ge_customer: currentFormData.is_ge_customer
      },
    };
    await createCustomer(dispatch, createCustomerReqObj, allCustomersID)
  }
  
  const servicesConfigList = generateServiceConfigList(currentFormData);
  const promises = [];
  for (const serviceConfig of servicesConfigList) {
    let updateServiceReqObj: ServicesConfig = {
      customer_id: customerId,
      service: serviceConfig,
    };
    promises.push(await updateCustomerServices(dispatch, updateServiceReqObj));
  }

  Promise.all(promises).then(() => {
    dispatch(activateFullScreenPrompt("false"));
    dispatch(setPageKey("Complete"));
    reloadCustomerDetails(dispatch, customerId);
  });
};

const createCustomer = async (
  dispatch: any,
  reqObj: CreateCustomerRequest,
  allCustomersID: string[]
) => {
  await createCustomerInDb(msalInstance, JSON.stringify(reqObj))
  .then(() => {
    dispatch(setAllCustomersID([...allCustomersID, reqObj.customer.id]));
    return true;
  })
  .catch((e) => {
    console.log("Error in createCustomerInDb: " + e);
    dispatch(resetPageKey());
    dispatch(clearFormData());
    dispatch(activateFullScreenPrompt("error"));
    return false;
  })
};

const updateCustomerServices = async (
  dispatch: any,
  reqObj: ServicesConfig
) => {
  await addServicesToCustomer(msalInstance, JSON.stringify(reqObj))
  .then(() => {
    return true;
  })
  .catch((e) => {
      console.log("Error in addServicesToCustomer: " + e);
      dispatch(activateFullScreenPrompt("error"));
      return false;
    }
  );
};

const reloadCustomerDetails = async (dispatch: any, customerId: string) => {
  await getCustomer(msalInstance, customerId as string)
    .then((response) => {
      dispatch(updateCustomerInfo(response.data[0]["customer_info"]));
      dispatch(updateReportInfo(response.data[0]["report_info"]));
      dispatch(updateBaselineInfo(response.data[0]["baseline_info"]));
    })
    .catch((e) => {
      console.log("Error getting customer info: " + e);
    });

  await getHealthCheckData(msalInstance, customerId as string)
    .then((response) => {
      dispatch(updateHealthCheckInfo(response.data));
    })
    .catch((e) => {
      console.log("Error loading healthcheck information: " + e);
    });
};

export default handleFormSubmit;

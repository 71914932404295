import { createSlice } from "@reduxjs/toolkit";
import { Customer } from "../models/CustomerModel";

const initialState: Customer = {
    customer_info: {
        logged_in_users:[],
        customer_id: "",
        customer_name: "",
        tenant_id: "",
        products: [],
        sentinel_configs: [],
        enabled: true,
        reporting_enabled: true
    },
    report_info: {
        total_records:0,
        published_records: 0,
        unpublished_records: 0
    },
    baseline_info: {
        total_records: 0,
        new_accepted_record: 0,
        historical_records: 0,
        new_accepted_record_date: ""
    },
    healthcheck_info: {
        customer_id: "",
        hourly: [],
        daily: [],
        weekly: [],
        ignoredResources: []
    },
    acknowledged: {}
}

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        updateCustomerInfo: (state, action) => {
            state.customer_info = action.payload
        },
        updateReportInfo: (state, action) => {
            state.report_info = action.payload
        },
        updateBaselineInfo: (state, action) => {
            state.baseline_info = action.payload
        },
        updateHealthCheckInfo: (state, action) => {
            state.healthcheck_info = action.payload
        },
        addIgnoredResource: (state, action) => {
            state.healthcheck_info.ignoredResources.push(action.payload)
        },
        removeIgnoredResource: (state, action) => {
            state.healthcheck_info.ignoredResources = state.healthcheck_info.ignoredResources
                .filter(resource => !(resource.resource_name === action.payload.resource_name && resource.resource_type === action.payload.resource_type))
        },
        setIgnoredResourceApproved: (state, action) => {
            state.healthcheck_info.ignoredResources = state.healthcheck_info.ignoredResources
                .map(resource => {
                    if (resource.resource_name === action.payload.entry.resource_name && resource.resource_type === action.payload.entry.resource_type) {
                        return {...resource, approved: action.payload.approve}
                    } else {
                        return resource
                    }
                })
        },
        updateCustomerEnabledStatus: (state, action) => {
            state.customer_info["enabled"] = action.payload["enabled"]
            state.customer_info["reporting_enabled"] = action.payload["reporting_enabled"]
        }
    }
})

export const {updateCustomerInfo, updateReportInfo, updateBaselineInfo, updateHealthCheckInfo, addIgnoredResource, removeIgnoredResource, setIgnoredResourceApproved, updateCustomerEnabledStatus} = customerSlice.actions
export default customerSlice.reducer
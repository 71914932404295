import { createSlice } from "@reduxjs/toolkit";

export interface jiraRecordAction {
    payload: {
        product: string,
        organisation: string,
        customer: string,
        project: string,
    }
}

export interface FormDataState {
    existing_services: string[];
    available_services: string[];
    id: string;
    company_name: string;
    tid: string;
    is_ge_customer: boolean;
    selected_products: string[];
    jira_record: {
        "ice_imhr": {
            organisation: string,
            customer: string,
            project: string,
        },
        "secure_connect": {
            organisation: string,
            customer: string,
            project: string,
        },
    }
    squad_name: string;
    sentinel_records:  {
          workspace: string
          workspace_id: string
          resource_group: string
          subscription_id: string
        }[];
    sentinel_metadata: {
        alert_sources: {}
    },
    logic_monitor: {
        company_name: string,
    },
    sherlock_sub_id: string,

    teams_webhook_ice: string;
    teams_webhook_secure_connect: string;
    teams_webhook_sherlock: string;
    teams_webhook_s365: string;
    s365_id: string
    }

const initialState: FormDataState = {
    existing_services: [],
    available_services: [],
    id: "",
    company_name: "",
    tid: "",
    is_ge_customer: false,
    selected_products: [],
    jira_record: {
        "ice_imhr": {
            organisation: "",
            customer: "",
            project: "",
        },
        "secure_connect": {
            organisation: "",
            customer: "",
            project: "",
        },
    },
    squad_name: "",
    sentinel_records: [{
        workspace: "",
        workspace_id: "",
        resource_group: "",
        subscription_id: "",
    }],
    sentinel_metadata: {
        alert_sources: { 
            "AADIDP": "Azure Active Directory Identity Protection",
            "MDCA": "Microsoft Cloud App Security",
            "MDE": "Microsoft Defender Advanced Threat Protection",
            "MDO": "Office 365 Advanced Threat Protection"
        }
    },
    logic_monitor: {
        company_name: "",
    },    
    sherlock_sub_id: "",

    teams_webhook_ice: "",
    teams_webhook_secure_connect: "",
    teams_webhook_sherlock: "",
    teams_webhook_s365: "",
    s365_id: ""
}

export const formDataSlice = createSlice({
    name: "formData",
    initialState,
    reducers: {
        setExistingServices: (state, action) => {
            state.existing_services = action.payload
        },
        setAvailableServices: (state, action) => {
            state.available_services = action.payload
        },
        clearFormData: (state) => {
            state.existing_services = [];
            state.available_services = [];
            state.id = "";
            state.company_name = "";
            state.tid = "";
            state.is_ge_customer = false;
            state.selected_products = [];
            state.jira_record = {
                "ice_imhr": {
                    organisation: "",
                    "customer": "",
                    project: "",
                },
                "secure_connect": {
                    organisation: "",
                    customer: "",
                    project: "",
                },
            };
            state.squad_name = "";
            state.sentinel_records = [{
                workspace: "",
                workspace_id: "",
                resource_group: "",
                subscription_id: "",
            }]
            state.logic_monitor = {
                company_name: "",
            }
            state.sherlock_sub_id = "";
            state.teams_webhook_ice = "";
            state.teams_webhook_secure_connect = "";
            state.teams_webhook_sherlock = "";
            state.teams_webhook_s365 = "";
            state.s365_id = ""
        },
        setCustomerNameId: (state, action) => {
            state.company_name = action.payload
            state.id = state.company_name.trim().toLowerCase().replace(/ /g, "_")
        },
        setTenantId: (state,action) => {
            state.tid = action.payload
        },
        setSelectedProducts: (state, action) => {
            if (!state.selected_products.includes(action.payload)) {
                // add product to selected products and sort alphabetically
                state.selected_products = [...state.selected_products, action.payload].sort()
            }
        },
        setIsGECustomer: (state, action) => {
            state.is_ge_customer = action.payload
        },
        removeSelectedProducts: (state, action) => {
            state.selected_products = state.selected_products.filter((selected_products) => selected_products !== action.payload)
        },
        resetSelectedProducts: (state) => {
            state.selected_products = []
        },
        setJiraOrganisation: (state, action: jiraRecordAction) => {
            state.jira_record[action.payload.product as keyof typeof state.jira_record].organisation = action.payload.organisation;
        },
        setJiraCustomer: (state, action: jiraRecordAction) => {
            state.jira_record[action.payload.product as keyof typeof state.jira_record].customer = action.payload.customer;
        },
        setJiraProject: (state, action: jiraRecordAction) => {
            state.jira_record[action.payload.product as keyof typeof state.jira_record].project = action.payload.project;
        },
        setJiraSquad: (state, action) => {
            state.squad_name = action.payload
        },
        addSentinelRecord: (state) => {
            state.sentinel_records = [...state.sentinel_records, 
                {
                    workspace: "",
                    workspace_id: "",
                    resource_group: "",
                    subscription_id: "",
                }
            ]
        },
        removeSentinelRecord: (state, action) => {
            state.sentinel_records = state.sentinel_records.filter((record, index) => index !== action.payload)
        },
        setSentinelRecords: (state, action) => {
            state.sentinel_records = action.payload;
        },
        setSentinelWorkspace: (state, action) => {
            state.sentinel_records[action.payload.index]["workspace"] = action.payload.workspace
        },
        setSentinelWorkspaceId: (state, action) => {
            state.sentinel_records[action.payload.index]["workspace_id"] = action.payload.workspaceId
        },
        setSentinelResourceGroup: (state, action) => {
            state.sentinel_records[action.payload.index]["resource_group"] = action.payload.resourceGroup
        },
        setSentinelSubscriptionId: (state, action) => {
            state.sentinel_records[action.payload.index]["subscription_id"] = action.payload.subscriptionId
        },
        setLogicMonitorRecord: (state, action) => {
            state.logic_monitor["company_name"] = action.payload
        },
        setSherlockSubId: (state, action) => {
            state.sherlock_sub_id = action.payload
        },
        setTeamsWebhookIce: (state, action) => {
            state.teams_webhook_ice = action.payload
        },
        setTeamsWebhookSecureConnect: (state, action) => {
            state.teams_webhook_secure_connect = action.payload
        },
        setTeamsWebhookSherlock: (state, action) => {
            state.teams_webhook_sherlock = action.payload
        },
        setTeamsWebhookS365: (state, action) => {
            state.teams_webhook_s365 = action.payload
        },
        setS365Id: (state, action) => {
            state.s365_id = action.payload
        }
    }
})

export const { clearFormData, setCustomerNameId, setExistingServices, setAvailableServices, setTenantId, setSelectedProducts, setIsGECustomer, removeSelectedProducts, resetSelectedProducts, setJiraOrganisation, setJiraCustomer, setJiraProject, setJiraSquad, addSentinelRecord, removeSentinelRecord, setSentinelRecords, setSentinelWorkspace, setSentinelWorkspaceId, setSentinelResourceGroup, setSentinelSubscriptionId, setLogicMonitorRecord, setSherlockSubId, setTeamsWebhookIce, setTeamsWebhookSecureConnect, setTeamsWebhookSherlock, setTeamsWebhookS365, setS365Id } = formDataSlice.actions
export default formDataSlice.reducer
import { useAppSelector } from "../../../redux/hook";
import { FormCreateCustomer } from "../createCustomerForm/FormCreateCustomer";
import { FormUpdateCustomer } from "../updateCustomerForm/FormUpdateCustomer";
import { FormComplete } from "../pages/FormComplete";
import { FormIce } from "../pages/FormIce";
import { FormSecureConnect } from "../pages/FormSecureConnect";
import { FormSherlock } from "../pages/FormSherlock";
import { FormS365 } from "../pages/FormS365";

// UPDATE THIS AS NEW SERVICES ARE ADDED TO THE BACKEND
const FormElementMapping = () => {
  const currentFormType = useAppSelector((state) => state.form.formType);
  let infoPage = null;
  switch (currentFormType) {
    case "CustomerCreation":
      infoPage = <FormCreateCustomer />;
      break;
    case "CustomerManagement":
      infoPage = <FormUpdateCustomer />;
      break;
  }
  return {
    "Info": infoPage,
    "iCE IMHR": <FormIce />,
    "Secure Connect": <FormSecureConnect />,
    "Sherlock": <FormSherlock />,
    "Secure 365": <FormS365 />,
    "Complete": <FormComplete />,
  };
};

export default FormElementMapping;

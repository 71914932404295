// A place to centralise some theme information so we can change the theme more easily
export const Theme = {
    Colors: {
      DarkWebBg: 'bg-ui-darkweb',
      NeonGreenBg: "bg-ui-neonGreen",
      ElectricGreenBg: "bg-ui-electricGreen",
      Primary: 'bg-ui-lime',
      Error: 'bg-ui-red',
      Shadow: 'shadow-slate-900',
      Disabled: 'bg-ui-red-300'
    }
  };
  
  export default Theme;
  
import {
    setS365Id,
    setTeamsWebhookS365,
  } from "../../../redux/formDataSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import FormTitle from "../utilities/FormTitle";
import FormInput from "../utilities/FormInput";
import { setFormInvalid, setFormValid } from "../../../redux/formSlice";
import { useEffect } from "react";
  
const FormS365 = () => {
    const dispatch = useAppDispatch();
    const currentFormData = useAppSelector((state) => state.formData);
    const isFieldValidated = currentFormData.s365_id !== "";

    useEffect(() => {
        if (isFieldValidated) dispatch(setFormValid());
        else dispatch(setFormInvalid());
    }, [isFieldValidated]);

    return (
        <div>
        <FormTitle />
        <FormInput
            title="Secure 365 Customer ID"
            name="secure_365-id"
            extraStyle="font-bold"
            value={currentFormData.s365_id}
            onChangeAction={(e) => dispatch(setS365Id(e.target.value))}
        />
        <FormInput
            title="Teams Webhook (Optional)"
            name="team-webhook"
            extraStyle="font-bold"
            required={false}
            value={currentFormData.teams_webhook_s365}
            placeholder="The Customer MS Team Webhook to use as comms channel for notification..."
            onChangeAction={(e) =>
            dispatch(setTeamsWebhookS365(e.target.value))
            }
        />
        </div>
    );
};

export { FormS365 };
  